$ln-assets-url: '~luna-style';
@import 'luna-react/scss/style';

body {
  margin: 0;
  padding: 0;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[role='group']:focus {
  box-shadow: none;
}

h1 {
  color: #f06c00;
}
